import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@components/breadcrumbs';
import { Button, Card, CardBody } from 'reactstrap';
import { PlusSquare } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { deleteItem, closeDeleteDialog, remove, fetchAll } from "@src/redux/bundles";
import { useNavigate } from 'react-router-dom';
import Table from '@src/components/DataTable/Table';
import { columns } from "./columns";
import ConfirmDialog from "@src/components/shared/ConfirmDialog";
import { FilterMatchMode, FilterOperator } from 'primereact/api';

const List = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { items, deleteDialogOpened, itemToDelete, ...metaData } = useSelector(state => state.bundle);

    const [filters, setFilters] = useState(null);
    const [filter, setFilter] = useState({
        pageNumber: 1,
        pageSize: 10,
        orderBy: [],
        advancedFilter: null,
        keyword: ""
    });

    useEffect(() => { initFilters() }, []);
    useEffect(() => {
        dispatch(fetchAll({ ...filter }));
    }, [filter]);

    const initFilters = () => {
        setFilters({
            name: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            discreption: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            mealId: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            market: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            cancellationFee: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            baggageAllowance: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            loungeAccess: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            cancellation: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            changeFee: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            change: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
    };

    const handleAdd = () => navigate("new");

    const handleEdit = ({ id }) => navigate(`${id}`);

    const handleDelete = (item) => {
        dispatch(deleteItem(item));
    };

    const confirmRemoveItem = () => {
        const id = itemToDelete?.id;
        dispatch(remove({ id }));
    };

    return (
        <>
            <Breadcrumbs title='Bundles' data={[{
                title: 'Bundles',
            }]} />
            <Card>
                <CardBody >
                    <div className="text-end">
                        <Button className='mb-2' onClick={handleAdd}>
                            Add <PlusSquare size={20} />
                        </Button>
                    </div>
                    <Table
                        metaData={metaData}
                        columns={columns(handleEdit, handleDelete)}
                        emptyMessage="No Bundles found."
                        tableState={filter}
                        filters={filters}
                        setFilters={setFilters}
                        setTableState={setFilter}
                        globalFilterFields={['type', 'price', 'status']}
                        values={items}
                        dataKey="id"
                    />
                </CardBody>
            </Card >
            <ConfirmDialog
                visible={deleteDialogOpened}
                close={() => dispatch(closeDeleteDialog())}
                item={{}}
                onConfirm={confirmRemoveItem}
            />
        </>
    );
}

export default List;