import { DefaultRoute } from "../router/Router";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) =>obj? Object.keys(obj).length === 0:true;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});
// export const timezoneObjectsArray = UTC_TIMEZONE_LIST.map(timezone => {
//   const sign = timezone[3] === '+' ? 1 : -1;
//   const hours = parseInt(timezone.slice(4, 6));
//   const minutes = parseInt(timezone.slice(7));
//   const totalMinutes = sign * (hours * 60 + minutes);
//   return {
//       label: timezone,
//       value: totalMinutes
//   };
// });
export const UTC_TIMEZONE_LIST=[
  {
      "label": "UTC-12:00",
      "value": -720
  },
  {
      "label": "UTC-11:00",
      "value": -660
  },
  {
      "label": "UTC-10:00",
      "value": -600
  },
  {
      "label": "UTC-09:30",
      "value": -570
  },
  {
      "label": "UTC-09:00",
      "value": -540
  },
  {
      "label": "UTC-08:00",
      "value": -480
  },
  {
      "label": "UTC-07:00",
      "value": -420
  },
  {
      "label": "UTC-06:00",
      "value": -360
  },
  {
      "label": "UTC-05:00",
      "value": -300
  },
  {
      "label": "UTC-04:00",
      "value": -240
  },
  {
      "label": "UTC-03:30",
      "value": -210
  },
  {
      "label": "UTC-03:00",
      "value": -180
  },
  {
      "label": "UTC-02:00",
      "value": -120
  },
  {
      "label": "UTC-01:00",
      "value": -60
  },
  {
      "label": "UTC+00:00",
      "value": 0
  },
  {
      "label": "UTC+01:00",
      "value": 60
  },
  {
      "label": "UTC+02:00",
      "value": 120
  },
  {
      "label": "UTC+03:00",
      "value": 180
  },
  {
      "label": "UTC+03:30",
      "value": 210
  },
  {
      "label": "UTC+04:00",
      "value": 240
  },
  {
      "label": "UTC+04:30",
      "value": 270
  },
  {
      "label": "UTC+05:00",
      "value": 300
  },
  {
      "label": "UTC+05:30",
      "value": 330
  },
  {
      "label": "UTC+05:45",
      "value": 345
  },
  {
      "label": "UTC+06:00",
      "value": 360
  },
  {
      "label": "UTC+06:30",
      "value": 390
  },
  {
      "label": "UTC+07:00",
      "value": 420
  },
  {
      "label": "UTC+08:00",
      "value": 480
  },
  {
      "label": "UTC+08:45",
      "value": 525
  },
  {
      "label": "UTC+09:00",
      "value": 540
  },
  {
      "label": "UTC+09:30",
      "value": 570
  },
  {
      "label": "UTC+10:00",
      "value": 600
  },
  {
      "label": "UTC+10:30",
      "value": 630
  },
  {
      "label": "UTC+11:00",
      "value": 660
  },
  {
      "label": "UTC+12:00",
      "value": 720
  },
  {
      "label": "UTC+12:45",
      "value": 765
  },
  {
      "label": "UTC+13:00",
      "value": 780
  },
  {
      "label": "UTC+14:00",
      "value": 840
  }
];
