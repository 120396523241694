// ** Reactstrap Imports
import { Label, FormFeedback,Row,Col } from 'reactstrap'

// ** Third Party Components
import { useFormContext, Controller } from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import classnames from "classnames";
import "@styles/react/libs/flatpickr/flatpickr.scss";


const PickerDateTime = ({ label, name,enableTime=false,className,...props }) => {
    // ** State
    const { control, formState: { errors }, setValue, trigger, getValues } = useFormContext();
    const { [name]: value } = getValues();
    return (

        <Row>
            <Col md='4' sm='12'>
                <Label className='form-label' for={name}>
                    <h6>{label}</h6>
                </Label>
            </Col>
            <Col md='8' sm='12'>
                <Controller
                    id={name}
                    name={name}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <Flatpickr
                                className={classnames(`form-control ${className}`, {
                                    "is-invalid": error && true
                                })}
                                // data-enable-time
                                
                                id='date-time-picker'
                                value={value}
                                onBlur={() => trigger(name)}
                                onChange={date => setValue(name, date)}
                                {...props}
                            />
                            {error && <FormFeedback>{error.message}</FormFeedback>}
                        </>
                    )}
                    
                />
            </Col>

        </Row>


    )
}

export default PickerDateTime
