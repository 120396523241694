import { fetchAll, deleteItem, closeDeleteDialog, remove } from "@src/redux/airports";
import Table from '@src/components/DataTable/Table';
import { Button, Card, CardBody } from "reactstrap";
import { PlusSquare } from "react-feather";
import Breadcrumbs from '@components/breadcrumbs';
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useEffect } from "react";
import { columns } from "./columns";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from "@src/components/shared/ConfirmDialog";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function List() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { items, deleteDialogOpened, itemToDelete, ...metaData } = useSelector(state => state.airports);

    const [filters, setFilters] = useState(null);

    const [filter, setFilter] = useState({
        pageNumber: 1,
        pageSize: 10,
        orderBy: [],
        advancedFilter: null,
        keyword: ""
    });

    useEffect(() => { initFilters() }, []);
    useEffect(() => {
        dispatch(fetchAll({ ...filter }));
    }, [filter]);

    const initFilters = () => {
        setFilters({
            id: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            name: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            departureTerminal: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            arrivalTerminal: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            intDepartureTerminal: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            intArrivalTerminal: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            cityName: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            // type: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            // currency: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            // ["issuedDate.Date"]: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            // source: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            // sourceId: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        // setGlobalFilterValue('');
    };

    const handleAdd = () => navigate("new");
    const handleEdit = ({ id }) => navigate(`${id}`);

    const handleDelete = (item) => {
        dispatch(deleteItem(item));
    };

    const confirmRemoveItem = () => {
        const id = itemToDelete?.id;
        dispatch(remove({ id }));
    };

    const localizedBodyTemplate = (propName, rowData) => {
        const value = rowData[propName];
        return `${value?.en}`;
    };

    const actionBodyTemplate = (onEdit, onDelete) => rowData => <div className="d-flex justify-content-center align-items-center">
        <Button onClick={() => onEdit(rowData)}
            color="flat-primary"
            className="btn-icon"
            size="sm" >
            <Edit id="edit" size={20} />
        </Button>
        {/* <Button onClick={() => onDelete(rowData)}
        color="flat-danger"
        className="btn-icon"
        size="sm" >
        <Trash2 id="delete" size={22} />
    </Button> */}
    </div>

    return (
        <>
            <Breadcrumbs title='Airports' data={[{
                title: 'Airports',
            }]} />

            <Card>
                <CardBody >
                    <div className="text-end">
                        <Button className='mb-2' onClick={handleAdd}>
                            Add <PlusSquare size={20} />
                        </Button>
                    </div>
                    <Table
                        metaData={metaData}
                        columns={columns(handleEdit, handleDelete)}
                        tableState={filter}
                        filters={filters}
                        setFilters={setFilters}
                        setTableState={setFilter}
                        globalFilterFields={['type', 'price', 'status']}
                        values={items || []}
                        dataKey="id"
                        emptyMessage="No Airports Found..."
                    />
                    {/* <DataTable value={items} tableStyle={{ minWidth: '50rem' }}>
                        <Column field='name' header='Name' filter={true} sortable={true} body={(values) => localizedBodyTemplate("name", values)} ></Column>
                        <Column field='departureTerminal' header='Departure Terminal' filter={true} body={(values) => localizedBodyTemplate("departureTerminal", values)}></Column>
                        <Column field='arrivalTerminal' header='Arrival Terminal' filter={true} body={(values) => localizedBodyTemplate("arrivalTerminal", values)}></Column>
                        <Column field='intDepartureTerminal' header='Int. Departure Terminal' filter={true} body={(values) => localizedBodyTemplate("intDepartureTerminal", values)}></Column>
                        <Column field='intArrivalTerminal' header='Int. Arrival Terminal' filter={true} body={(values) => localizedBodyTemplate("intArrivalTerminal", values)}></Column>
                        <Column field='cityId' header='City' filter={true} sortable={true}></Column>
                        <Column field='actions' header='Actions' style={{ width: '12rem' }} body={actionBodyTemplate(handleEdit, handleDelete)}></Column>
                    </DataTable> */}
                </CardBody>
            </Card >
            <ConfirmDialog
                visible={deleteDialogOpened}
                close={() => dispatch(closeDeleteDialog())}
                item={{}}
                onConfirm={confirmRemoveItem}
            />
        </>
    );
}

export default List;
