import React, { useEffect } from 'react';
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocalizedInput, TextInput } from "@src/components/inputs";
import { useSelector, useDispatch } from "react-redux";
import { create, update, toggleModal } from "@src/redux/meals";
import { Button, Form, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../@core/components/spinner/Loading-spinner";

const AddMeal = () => {

    const dispatch = useDispatch();

    const { selectedItem, errors, error, openModal, Mode, Item, loading } = useSelector((state) => state.meal);

    const initialValues = () => {
        if (Mode !== "Edit") {
            // alert("Add");
            return {
                name: { en: "", ar: "" },
                code: "",
            };
        } else {
            return {
                name: { en: Item.name?.en, ar: Item.name?.ar },
                code: Item?.id,
            };
        };
    };

    useEffect(() => {
        reset(initialValues());
    }, [Mode]);

    const localizedValidation = yup.object().shape({
        en: yup.string().required("English Name is Required"),
        ar: yup.string().required("Arabic Name is Required"),
    });

    const validationSchema = yup.object().shape({
        name: localizedValidation,
        code: yup.string().required("Code is Required"),
    });

    // ** Hooks
    const methods = useForm({
        defaultValues: initialValues(),
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    const { handleSubmit, reset, getValues } = methods;

    let onSubmit = (item) => {
        if (Item) {
            dispatch(update({ item, successCallBack: () => { return; } }))
        } else {
            dispatch(create({ item, successCallBack: () => { return; } }));
        }
    };

    const toggle = () => {
        dispatch(toggleModal({ openModal: false, Item: undefined, Mode: undefined }));
    };

    return (
        <>
            <Modal isOpen={openModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <h2>{Mode === "Edit" ? "Edit" : "Add"} Meal</h2>
                </ModalHeader>
                <ModalBody>
                    <FormProvider {...methods}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {loading &&
                                <div style={{ position: "absolute", marginLeft: "40%", marginTop: "30px", zIndex: "5" }}>
                                    <Loader />
                                </div>
                            }
                            <Row style={{ opacity: loading ? "0.5" : "1" }}>
                                {errors &&
                                    errors.map(err =>
                                        <Col className='mb-3' sm='12'>
                                            <div className="text-danger">{err.errorMessage}</div>
                                        </Col>
                                    )}
                            </Row>
                            <Row style={{ opacity: loading ? "0.5" : "1" }}>
                                <Col className="" sm="12">
                                    <LocalizedInput label="Meal Name" name="name" disabled={loading} />
                                </Col>
                                <Col className="mb-1" sm="12">
                                    <TextInput label="Code" name="code" disabled={loading || Mode === "Edit"} />
                                </Col>
                            </Row>
                            {error &&
                                <div className="text-danger">{error}</div>
                            }
                        </Form>
                    </FormProvider>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggle} disabled={loading}>
                        Cancle
                    </Button>{' '}
                    <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={loading}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default AddMeal;