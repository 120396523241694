import { lazy } from 'react';

// const List = lazy(() => import('@src/views/terminals/List'));
import List from "./List";

const PageLayoutRoutes = [
  {
    path: "/terminals",
    index: true,
    element: <List />
  },
];

export default PageLayoutRoutes;
