import { fetchAll, deleteItem, closeDeleteDialog, toggleModal, remove } from "@src/redux/cities";
import Table from '@src/components/DataTable/Table';
import { Button, Card, CardBody } from "reactstrap";
import Form from "./Form";
import Breadcrumbs from '@components/breadcrumbs';
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useEffect } from "react";
import { columns } from "./columns";
import { useSelector, useDispatch } from 'react-redux';
import ConfirmDialog from "@src/components/shared/ConfirmDialog";
import { PlusSquare } from "react-feather";

function List() {

    const dispatch = useDispatch();

    const { items, deleteDialogOpened, itemToDelete, ...metaData } = useSelector(state => state.city);

    const [filter, setFilter] = useState({
        pageNumber: 1,
        pageSize: 10,
        orderBy: [],
        advancedFilter: null,
        keyword: ""
    });

    useEffect(() => { initFilters() }, []);
    useEffect(() => {
        dispatch(fetchAll({ ...filter }));
    }, [filter]);


    const [filters, setFilters] = useState(null);

    const initFilters = () => {
        setFilters({
            name: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            id: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            timeZone: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            countryName: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        });
    };

    const handleAdd = () => {
        dispatch(toggleModal({ openModal: true, Item: undefined, Mode: "Add" }));
    };

    const handleEdit = (rowData) => {
        dispatch(toggleModal({ openModal: true, Item: rowData, Mode: "Edit" }));
    };

    const handleDelete = (item) => {
        dispatch(deleteItem(item));
    };

    const confirmRemoveItem = () => {
        const id = itemToDelete?.id;
        dispatch(remove({ id }));
    };

    return (
        <>
            <Breadcrumbs title='Cities' data={[{
                title: 'Cities',
            }]} />
            <Form />
            <Card>
                <CardBody >
                    <div className="text-end">
                        <Button className='mb-2' onClick={handleAdd}>
                            Add <PlusSquare size={20} />
                        </Button>
                    </div>
                    <Table
                        metaData={metaData}
                        columns={columns(handleEdit, handleDelete)}
                        tableState={filter}
                        filters={filters}
                        setFilters={setFilters}
                        setTableState={setFilter}
                        rowsPerPageOptions={[10, 25, 50,100]}
                        globalFilterFields={['type', 'price', 'status']}
                        values={items || []}
                        dataKey="id"
                    />
                </CardBody>
            </Card >
            <ConfirmDialog
                visible={deleteDialogOpened}
                close={() => dispatch(closeDeleteDialog())}
                item={{}}
                onConfirm={confirmRemoveItem}
            />
        </>
    );
}

export default List;
