import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@components/breadcrumbs';
import { toggleModal, fetchAll, remove, deleteItem, closeDeleteDialog } from "@src/redux/meals";
import Table from '@src/components/DataTable/Table';
import { Button, Card, CardBody } from "reactstrap";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { columns } from "./columns";
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import { PlusSquare } from 'react-feather';
import ConfirmDialog from "@src/components/shared/ConfirmDialog";

const List = () => {

    const dispatch = useDispatch();

    const { items, deleteDialogOpened, itemToDelete, ...metaData } = useSelector(state => state.meal);

    const [filter, setFilter] = useState({
        pageNumber: 1,
        pageSize: 10,
        orderBy: [],
        advancedFilter: null,
        keyword: ""
    });

    
    const [filters, setFilters] = useState(null);

    useEffect(() => { initFilters() }, []);
    useEffect(() => {
        dispatch(fetchAll({ ...filter }));
    }, [filter]);

    const initFilters = () => {
        setFilters({
            name: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            id: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        });
    };

    

    const handleAdd = () => {
        dispatch(toggleModal({ openModal: true, Item: undefined, Mode: "Add" }));
    };

    const handleEdit = (rowData) => {
        dispatch(toggleModal({ openModal: true, Item: rowData, Mode: "Edit" }));
    };

    const handleDelete = (item) => {
        dispatch(deleteItem(item));
    };

    const confirmRemoveItem = () => {
        const id = itemToDelete?.id;
        dispatch(remove({ id }));
    };

    return (
        <>
            <Breadcrumbs title='Meals' data={[{
                title: 'Meals',
            }]} />
            <Form />
            <Card>
                <CardBody >
                    <div className="text-end">
                        <Button className='mb-2' onClick={handleAdd}>
                            Add <PlusSquare size={20} />
                        </Button>
                    </div>
                    <Table
                        metaData={metaData}
                        columns={columns(handleEdit, handleDelete)}
                        emptyMessage="No Meal Data found."
                        tableState={filter}
                        filters={filters}
                        setFilters={setFilters}
                        setTableState={setFilter}
                        globalFilterFields={['name']}
                        values={items || []}
                        dataKey="id"
                    />
                </CardBody>
            </Card>
            <ConfirmDialog
                visible={deleteDialogOpened}
                close={() => dispatch(closeDeleteDialog())}
                item={{}}
                onConfirm={confirmRemoveItem}
            />
        </>
    );
}

export default List;