import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, Row, Col, Card, CardBody, Label } from "reactstrap";
import { create, update, fetchById } from "@src/redux/bundles";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocalizedInput, TextInput, SelectInput } from "@src/components/inputs";
import Loader from "../../@core/components/spinner/Loading-spinner";
import config from "@src/configs/themeConfig";
import Breadcrumbs from '@components/breadcrumbs';
import { useNavigate, useParams } from "react-router-dom";
import { getAllMeals as fetchAllMeal } from "@src/redux/meals";
// import { fetchAll as fetchAllMeal } from "@src/redux/meals";


const AddBundles = () => {

    const [ssrFields, setSsrFields] = useState([{ key: "", value: "" }]);
    const [errorMsg, setErrorMsg] = useState([{ key: "", value: "" }]);
    const [errorMessage, setErrorMessage] = useState("");

    const baseRoute = config.app.baseRoute;

    const dispatch = useDispatch();

    const { id } = useParams();

    const navigate = useNavigate();

    const { errors, error, Mode, Item, loading } = useSelector((state) => state.bundle);

    const { items } = useSelector(state => state.meal);

    useEffect(() => {
        id != "new" && dispatch(fetchById({ id }));
    }, [id, Mode,]);

    useEffect(() => {
        if (Item?.ssr !== undefined) {
            const transformedArray = Object.entries(Item?.ssr).map(([key, value]) => ({
                key,
                value
            }));
            setSsrFields(transformedArray);
            const errorList = [];
            transformedArray.map(() => {
                errorList.push({ key: "", value: "" });
            });
            setErrorMsg(errorList);
        }
    }, [Item]);

    useEffect(() => {
        dispatch(fetchAllMeal());
    }, []);

    const initialValues = (item) => {
        if (id == "new" || !item) {
            return {
                code: Math.round(new Date().getTime() / 100).toString(),
                name: { en: "", ar: "" },
                discreption: { en: "", ar: "" },
                mealId: "",
                market: "",
                cancellationFee: "",
                baggageAllowance: { en: "", ar: "" },
                loungeAccess: { en: "", ar: "" },
                cancellation: { en: "", ar: "" },
                changeFee: "",
                change: { en: "", ar: "" },
                createdDate: new Date(),
                createdBy: "",
            };
        } else {
            return {
                id: item?.id,
                code: item?.code,
                name: { en: item?.name ? item?.name?.en : "", ar: item?.name ? item?.name?.ar : "" },
                discreption: { en: item?.discreption ? item?.discreption.en : "", ar: item?.discreption ? item?.discreption.ar : "" },
                mealId: item?.mealId,
                market: item?.market,
                cancellationFee: item?.cancellationFee,
                baggageAllowance: { en: item?.baggageAllowance?.en, ar: item?.baggageAllowance?.ar },
                loungeAccess: { en: item?.loungeAccess?.en, ar: item?.loungeAccess?.ar },
                cancellation: { en: item?.cancellation?.en, ar: item?.cancellation?.ar },
                changeFee: item?.changeFee,
                change: { en: item?.change?.en, ar: item?.change?.ar },
                createdDate: item?.createdDate ? item?.createdDate : new Date(),
                createdBy: item?.createdBy ? item?.createdBy : "",
            };
        };
    };

    useEffect(() => {
        reset(initialValues(Item))
    }, [Item]);

    const localizedValidation = yup.object().shape({
        en: yup.string().required("English Name is Required"),
        ar: yup.string().required("Arabic Name is Required"),
    });

    const validationSchema = yup.object().shape({
        name: localizedValidation,
        mealId: yup.string().required("Meal is Required"),
        market: yup.string().required("Market is Required"),
        cancellationFee: yup.string().required("This field is Required"),
        baggageAllowance: localizedValidation,
        loungeAccess: localizedValidation,
        cancellation: localizedValidation,
        changeFee: yup.string().required("This field is Required"),
        change: localizedValidation,
    });

    const methods = useForm({
        defaultValues: initialValues(Item),
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    const { handleSubmit, reset } = methods;

    let onSubmit = (item) => {
        let validate = true;
        setErrorMessage("");
        if (ssrFields.length === 0) {
            setErrorMessage("Enter atleast one SSR");
            validate = false;
        }
        const oldErrorMsg = [...errorMsg];
        ssrFields.map((val, ind) => {
            if (val.key === "") {
                validate = false;
                oldErrorMsg[ind] = {
                    ...oldErrorMsg[ind],
                    key: "Key is Required",
                }
            } else {
                oldErrorMsg[ind] = {
                    ...oldErrorMsg[ind],
                    key: "",
                }
            };
            if (val.value === "") {
                validate = false;
                oldErrorMsg[ind] = {
                    ...oldErrorMsg[ind],
                    value: "Value is Required",
                }
            } else {
                oldErrorMsg[ind] = {
                    ...oldErrorMsg[ind],
                    value: "",
                }
            };
        });
        setErrorMsg(oldErrorMsg);
        if (validate) {
            let objectData = {};
            ssrFields.map((val) => {
                objectData = {
                    ...objectData,
                    [val.key]: val.value
                }
            });
            const newData = {
                ...item,
                ssr: objectData,
            }
            id != "new"
                ? dispatch(update({ newData, successCallBack: () => navigate(-1) }))
                : dispatch(create({ newData, successCallBack: () => navigate(-1) }));
        };
    };

    const addFields = () => {
        const oldField = [...ssrFields];
        oldField.push({ key: "", value: "" });
        setSsrFields(oldField);

        const oldErrMsg = [...errorMsg];
        oldErrMsg.push({ key: "", value: "" });
        setErrorMsg(oldErrMsg);
        setErrorMessage("");
    };

    const removeTableRow = (ind) => {
        const newArray = [...ssrFields];
        newArray.splice(ind, 1);
        setSsrFields(newArray);
        const newErrArray = [...errorMsg];
        newErrArray.splice(ind, 1);
        setErrorMsg(newErrArray);
    };

    const handleChangeValue = (value, index, fieldName) => {
        setSsrFields((prev) => {
            const arrayData = [...prev];
            arrayData[index] = {
                ...arrayData[index],
                [fieldName]: value,
            }
            return arrayData;
        });
    };

    return (
        <>
            <Breadcrumbs title='Bundles'
                data={[{
                    title: 'Bundles',
                    link: `${baseRoute}/bundles`,
                }, {
                    title: 'Form',
                }]}
            />
            <Card>
                <CardBody >
                    <FormProvider {...methods}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm="12" style={{ opacity: loading ? "0.5" : "1" }}>
                                    {loading &&
                                        <div style={{ position: "absolute", marginLeft: "40%", marginTop: "30px", zIndex: "5" }}>
                                            <Loader />
                                        </div>
                                    }
                                    <Row style={{ opacity: loading ? "0.5" : "1" }}>
                                        {errors &&
                                            errors.map(err =>
                                                <Col className='mb-3' sm='12'>
                                                    <div className="text-danger">{err.errorMessage}</div>
                                                </Col>
                                            )}
                                    </Row>
                                    <Row style={{ opacity: loading ? "0.5" : "1" }}>
                                        <Col className="mb-1" sm="4">
                                            <LocalizedInput
                                                label="Name"
                                                name="name"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <LocalizedInput
                                                label="Discreption"
                                                name="discreption"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <LocalizedInput
                                                label="Change"
                                                name="change"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <LocalizedInput
                                                label="Baggage Allowance"
                                                name="baggageAllowance"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <LocalizedInput
                                                label="Lounge Access"
                                                name="loungeAccess"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <LocalizedInput
                                                label="Cancellation"
                                                name="cancellation"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <SelectInput
                                                label="Meal"
                                                name="mealId"
                                                defaultValue=""
                                                isDisabled={loading}
                                                options={items.map(val => ({
                                                    label: `${val.name.en}`,
                                                    value: val.id
                                                }))}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <TextInput
                                                label="Market"
                                                name="market"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mb-1" sm="4">
                                            <TextInput
                                                label="Cancellation Fee"
                                                name="cancellationFee"
                                                disabled={loading}
                                            />
                                        </Col>
                                        <Col className="mt-1" sm="4">
                                            <Label className='form-label'>
                                                SSR
                                            </Label>{" "}
                                            {ssrFields.map((val, ind) => {
                                                return (
                                                    <Row className='mb-1' key={ind}>
                                                        <Col sm="5">
                                                            <Input
                                                                label="key"
                                                                name="key"
                                                                placeholder="Key"
                                                                onChange={(e) => handleChangeValue(e.target.value, ind, "key")}
                                                                value={val.key}
                                                                className={errorMsg[ind]?.key != "" ? `is-invalid` : ""}
                                                                disabled={loading}
                                                            />
                                                            {errorMsg[ind]?.key && (
                                                                <p className="text-danger" style={{ fontSize: "13px", marginBottom: "-7px" }}>{errorMsg[ind]?.key}</p>
                                                            )}
                                                        </Col>
                                                        <Col sm="5">
                                                            <Input
                                                                label="value"
                                                                name="value"
                                                                placeholder="Value"
                                                                onChange={(e) => handleChangeValue(e.target.value, ind, "value")}
                                                                value={val.value}
                                                                className={errorMsg[ind]?.value != "" ? `is-invalid` : ""}
                                                                disabled={loading}
                                                            />
                                                            {errorMsg[ind]?.value && (
                                                                <p className="text-danger" style={{ fontSize: "13px", marginBottom: "-7px" }}>{errorMsg[ind]?.value}</p>
                                                            )}
                                                        </Col>
                                                        <Col sm="2">
                                                            <div>
                                                                <Button disabled={loading} onClick={() => removeTableRow(ind)} type='button'>X</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                            <Button disabled={loading} onClick={addFields}>Add</Button>
                                            {errorMessage && (
                                                <p className="text-danger" style={{ fontSize: "13px", marginBottom: "-7px" }}>{errorMessage}</p>
                                            )}
                                        </Col>
                                        <Col className="mt-1" sm="4">
                                            <TextInput
                                                label="Change Fee"
                                                name="changeFee"
                                                disabled={loading}
                                            />
                                        </Col>
                                    </Row>

                                    {error &&
                                        <div className="text-danger">{error}</div>
                                    }

                                    {/* <Button color="danger" onClick={() => navigate(-1)} disabled={loading}>
                                            Cancle
                                        </Button>{' '} */}
                                    <div className="text-end">
                                        <Button type="submit" disabled={loading}>
                                            {Item?.id ? "Update" : "Submit"}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </FormProvider>
                </CardBody>
            </Card>
        </>
    );
}

export default AddBundles;
