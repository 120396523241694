import { lazy } from 'react';

// const List = lazy(() => import('@src/views/airports/List'));
// const Form = lazy(() => import('@src/views/airports/Form'));
import List from "./List";
import Form from "./Form";

const PageLayoutRoutes = [
  {
    path: "/airports",
    index: true,
    element: <List />
  },
  {
    path: "/airports/:id",
    index: true,
    element: <Form />
  },
];

export default PageLayoutRoutes;
