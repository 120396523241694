import { Send, Home, MapPin, Database, Map } from "react-feather";
import React from "react";
export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/settings",
  },
  {
    id: "airports",
    title: "Airports",
    icon: <Send size={20} />,
    navLink: "/settings/airports",
  },
  // {
  //   id: "terminals",
  //   title: "Terminals",
  //   icon: <Map size={20} />,
  //   navLink: "/settings/terminals",
  // },
  {
    id: "cities",
    title: "Cities",
    icon: <MapPin size={20} />,
    navLink: "/settings/cities",
  },
  {
    id: "bundles",
    title: "Bundles",
    icon: <Database size={20} />,
    navLink: "/settings/bundles",
  },
  {
    id: "meals",
    title: "Meals",
    icon: <Database size={20} />,
    navLink: "/settings/meals",
  },
  {
    id: "countries",
    title: "Countries",
    icon: <MapPin size={20} />,
    navLink: "/settings/countries",
  },
];