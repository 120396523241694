import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

function ConfirmDialog({ visible, close, onConfirm, item }) {
  return (
    <Dialog
      visible={visible}
      style={{ width: "32rem" }}
      header="Confirm"
      modal
      footer={
        <>
          <Button label="No" icon="pi pi-times" outlined onClick={close} />
          <Button
            label="Yes"
            icon="pi pi-check"
            severity="danger"
            onClick={() => onConfirm(item)}
          />
        </>
      }
      onHide={close}
    >
      <div className="confirmation-content">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>Are you sure you want to confirm this action?</span>
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;
