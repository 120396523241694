import { Button } from "reactstrap";
import { Trash2, Edit } from "react-feather";

const actionBodyTemplate = (onEdit, onDelete) => rowData => <div className="d-flex justify-content-center align-items-center">
    <Button onClick={() => onEdit(rowData)}
        color="flat-primary"
        className="btn-icon"
        size="sm" >
        <Edit id="edit" size={20} />
    </Button>
    <Button onClick={() => onDelete(rowData)}
        color="flat-danger"
        className="btn-icon"
        size="sm" >
        <Trash2 id="delete" size={22} />
    </Button>
</div>

const localizedBodyTemplate = (propName, rowData) => {
    const value = rowData[propName];
    return `${value?.en} - ${value?.ar}`;
};

export const columns = (onEdit, onDelete) => [
    { field: 'name', header: 'Meal Name', filter: true, sortable: true, body: (values) => localizedBodyTemplate("name", values) },
    { field: 'id', header: 'Code', filter: true, sortable: true },
    { field: 'actions', header: 'Actions', style: { width: '10rem' }, body: actionBodyTemplate(onEdit, onDelete) },
];
