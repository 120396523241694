import { useFormContext, Controller } from 'react-hook-form'

import { Label, Input, FormFeedback, Row, Col, InputGroup, InputGroupText } from 'reactstrap'

import { User } from 'react-feather'


function TextInput({ label, icontext, icon, name, ...props }) {

    const languages = ['en', 'ar']
    const { control } = useFormContext();

    return languages.map(language => {

        let inputName = `${name}.${language}`
        return (
            <Row className="mb-1">
                <Col md='4' sm='12'>
                    <Label className='form-label' for={inputName}>
                        <h6>{label} ({language})</h6>
                    </Label>
                </Col>
                <Col md='8' sm='12'>
                    <Controller
                        id={inputName}
                        name={inputName}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            return (
                                icontext || icon ?
                                    <InputGroup>
                                        <InputGroupText>
                                            {icontext ? icontext : <User />}
                                        </InputGroupText>
                                        <Input placeholder={label} invalid={error && true} {...field} prefix='XY' {...props} />
                                        {error && <FormFeedback>{error?.message}</FormFeedback>}
                                    </InputGroup> :
                                    <>
                                        <Input placeholder={label} invalid={error && true} {...field} prefix='XY' {...props} />
                                        {error && <FormFeedback>{error?.message}</FormFeedback>}
                                    </>
                            )
                        }
                        }
                    />
                </Col>

            </Row>
        )
    })
}

export default TextInput

