import React, { Suspense, useEffect } from "react";

// ** Router Import
import Router from "./router/Router";
import { useUser} from "@app/layout";
const App = () => {
  useEffect(() => {
    // const alluser=useUser()
    // console.log(alluser)
    // setUser("Mohamed")

  }, [])
  
  return (
    <Suspense fallback={null}>
      <Router />
    </Suspense>
  );
};

export default App;
