import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch } from 'react-redux';
import { Label, Col, Row } from 'reactstrap';
import { useFormContext, Controller, useForm } from 'react-hook-form';

const AutoCompleteInput = ({ name, label, className = "", placeholder, mode, dispatchAction, listing = [], defaultValues = "", ...props }) => {

    const { control, formState: { errors }, trigger, setValue, watch } = useFormContext();

    const dispatch = useDispatch();

    const [value, setValues] = useState('');
    const [items, setItems] = useState([]);

    const search = (event) => {
        setValue(name, "");
        if (event?.query?.length > 2) {
            dispatch(dispatchAction({ filter: event?.query }));
        }
    };

    const handleSelect = (e) => {
        const selectedValue = listing?.find(item => item.name?.en === e.value);
        if (selectedValue) {
            setValue(name, selectedValue?.id);
            trigger(name);
        }
    };

    useEffect(() => {
        if (value === "") {
            setValue(name, "");
        }
    }, [value]);

    // useEffect(() => {
    //     if (defaultValues != "") {
    //         const findName = listing.find((item) => item.id == defaultValues);
    //         if (findName) {
    //             setValues(findName?.name?.en);
    //         }
    //     }
    // }, [listing]);

    useEffect(() => {
        if (defaultValues != "") {
            setValues(defaultValues);
            // dispatch(dispatchAction({ filter: "" }));
        }
    }, [defaultValues]);

    useEffect(() => {
        const newArray = listing?.map((val) => {
            return val.name?.en;
        });
        if (newArray.length === 0) {
            setItems(["Not found..."]);
        } else {
            setItems(newArray);
        }
    }, [listing]);

    return (
        <>
            <Row className='mt-1'>
                <Col>
                    <Label className='form-label' for={name}>
                        <h6>{label}</h6>
                    </Label>
                </Col>
                <Col md='8' sm='12'>

                    <Controller
                        id={name}
                        name={name}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            watch(name);
                            return (
                                <>
                                    <AutoComplete
                                        value={value}
                                        suggestions={items}
                                        completeMethod={search}
                                        onChange={(e) => setValues(e.value)}
                                        onSelect={handleSelect}
                                        placeholder={placeholder}
                                        name={name}
                                        inputClassName={error?.message ? `is-invalid ${className}` : className}
                                        inputStyle={{ height: "38.27px" }}
                                        {...props}
                                    />
                                    {error != undefined && <p class="invalid-feedback d-block">{error?.message}</p>}
                                </>
                            )
                        }}
                    />

                </Col>
            </Row>
        </>
    );
};

export default AutoCompleteInput;
