// ** Router imports
import { lazy } from 'react';
import * as reactDom from "react-router-dom";
import config from "@src/configs/themeConfig";
// ** GetRoutes
// import { getRoutes } from "./routes";
// ** Hooks Imports
import { useLayout, layoutConfig, useUser, getRoutes} from "@app/layout";
import navigation from '../navigation/horizontal';
const { Navigate } = reactDom;
const Home = lazy(() => import("../views/Home"));
import airports from '@src/views/airports';
import terminals from '@src/views/terminals';
import cities from '@src/views/cities';
import bundles from '@src/views/bundles';
import meals from '@src/views/meals';
import countries from "@src/views/countries";
// ** Merge Routes

const baseRoute = config.app.baseRoute

const mainRoutes = [
  {
    path: baseRoute,
    index: true,
    element: <Home />,
  },
  ...airports.map(r => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...terminals.map(r => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...cities.map(r => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...bundles.map(r => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...meals.map(r => ({ ...r, path: `${baseRoute}${r.path}` })),
  ...countries.map(r => ({ ...r, path: `${baseRoute}${r.path}` })),
];

const Router = () => {
  const { useRoutes } = reactDom;
  const { layout } = useLayout({ ...layoutConfig, });

  const allRoutes = getRoutes("horizontal", mainRoutes, reactDom,
    {
      navigations: [...navigation]
    });
  // const routes = reactDom.useRoutes(mainRoutes);
  const routes = useRoutes([...allRoutes]);

  return routes;
};

export default Router;