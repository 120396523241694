import { useFormContext, Controller, useForm } from 'react-hook-form'
import { Label, FormFeedback, Col, Row } from 'reactstrap'
import Select from 'react-select'
import classnames from "classnames";
import { useEffect } from 'react';

function MultiSelectInput({ label, defaultValue, name, options = [], optionKey = 'value', optionLabel = 'label', changeCallback, ...props }) {
    const { control, formState: { errors }, trigger, setValue, watch } = useFormContext();
    // const { [name]: value } = getValues();
    const value = watch(name);
    const selectedItems =
        options &&
        options?.filter((opt) => value?.some(v => v[optionKey] == opt[optionKey])) || [];

    const selectedObjs = options?.filter((a) =>
        optionKey ? value?.includes(a[optionKey]) : value?.includes(a)
    );


    const handleGetLabel = (o) => o[optionLabel];



    return (
        <Row>

            <Col>
                <Label className='form-label' for={name}>
                    <h6>{label}</h6>
                </Label>
            </Col>
            <Col md='8' sm='12'>

                <Controller
                    id={name}
                    name={name}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                        watch(name)
                        return (
                            <>

                                <Select
                                    className={classnames("react-select", {
                                        "is-invalid": error && true,
                                    })}
                                    isMulti
                                    key={optionKey}
                                    value={selectedObjs || []}

                                    classNamePrefix='select'
                                    options={options}
                                    defaultInputValue={defaultValue}
                                    isClearable
                                    menuPlacement="auto"
                                    isOptionDisabled={(option) => option.disabled}
                                    getOptionLabel={handleGetLabel}
                                    getOptionValue={(option) =>
                                        option[optionKey] != undefined ? option[optionKey] : option
                                    }
                                    onBlur={() => trigger(name)}
                                    onChange={value => {
                                        let values = value.map((val) => val[optionKey]);
                                        setValue(name, values)
                                        if (changeCallback) {
                                            changeCallback(values)
                                        }
                                    }}
                                    {...props}
                                />
                                {error && <FormFeedback>{error.message}</FormFeedback>}


                                {/* <Select
                                    isMulti
                                    name="colors"
                                    optionKey="flightNo"
                                    optionLabel="flightNo"
                                    options={options}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    getOptionLabel={(option) => {option[optionLabel]}}
                                    getOptionValue={(option) =>
                                        option[optionKey] != undefined ? option[optionKey] : option
                                    }
                                /> */}
                                {/* <Select
                                    
                                    key={optionKey}
                                    classNamePrefix='select'
                                    className="basic-multi-select"
                                    options={options}
                                    // defaultInputValue={defaultValue}
                                    // isClearable
                                    isMulti
                                    menuPlacement="auto"
                                    // isOptionDisabled={(option) => option.disabled}
                                    // getOptionLabel={handleGetLabel}
                                    // value={selectedItems}
                                    // getOptionValue={(option) =>
                                    //     option[optionKey] != undefined ? option[optionKey] : option
                                    // }
                                    // onBlur={() => trigger(name)}
                                    // onChange={value => {
                                    //     setValue(name, value ? value[optionKey] : '')
                                    //     if(changeCallback){
                                    //         changeCallback(value)
                                    //     }
                                    // }}
                                    {...props} 
                                /> */}
                                {error && <FormFeedback>{error.message}</FormFeedback>}
                            </>
                        )
                    }}
                />

            </Col>

        </Row>
    )
}

export default MultiSelectInput