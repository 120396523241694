import { lazy } from 'react';

// const List = lazy(() => import('@src/views/airports/List'));
import List from './List';
import Form from './Form';

const PageLayoutRoutes = [
    {
        path: "/bundles",
        index: true,
        element: <List />
    },
    {
        path: "/bundles/:id",
        index: true,
        element: <Form />
    },
];

export default PageLayoutRoutes;
