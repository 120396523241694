import { useFormContext, Controller, useForm } from 'react-hook-form'
import { Label, FormFeedback, Input, Col, Row } from 'reactstrap'
import classnames from "classnames";


const Radio = ({ name, label, options = [], optionKey = 'value', optionLabel = 'label', horizontalAlignment = false }) => {
    const { control, formState: { errors }, trigger, setValue, watch } = useFormContext();
    const value = watch(name);
    return <Controller
        id={name}
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
            // watch(name)
            return <div className={classnames({
                "d-flex my-1": horizontalAlignment
            })} >
                {options.map(o =>
                    <div
                        key={o[optionKey]}
                        className={classnames("form-check m-1", {
                            "m-0": horizontalAlignment
                        })} >


                        <Input type='radio' id={o[optionLabel]} 
                            checked={o[optionKey] == field.value}
                            onChange={() => {
                                setValue(name, o[optionKey])
                            }}
                        />
                        <Label className='form-check-label' for={o[optionLabel]}>
                            {o[optionLabel]}
                        </Label>
                    </div>

                )}
            </div>
        }} />
}


function RadioInput({ name, label, options = [], optionKey = 'value', optionLabel = 'label', horizontalAlignment = false }) {

    // const { [name]: value } = getValues();
    return label ?
        <Row className='align-items-baseline'>
            <Col md='4' sm='12'>
                <Label className='form-label' for={name}>
                    <h6>{label}</h6>
                </Label>
            </Col>
            <Col md='8' sm='12'>
                <Radio name={name} options={options} optionKey={optionKey} optionLabel={optionLabel} horizontalAlignment={horizontalAlignment} />
            </Col></Row> :
        <Radio name={name} options={options} optionKey={optionKey} optionLabel={optionLabel} horizontalAlignment={horizontalAlignment} />
}


export default RadioInput;