import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./RootApp";

import { useLayout } from './utility/hooks/useLayout'
import { Button as B } from 'reactstrap'
import { ThemeColors, ThemeContext } from './utility/context/ThemeColors'
import { store } from "./redux/store";
import Application from './App'
import { Provider } from "react-redux";
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;


// export const welcome = (message) => {
//   return `welcome ${message}`
// }
// export const Button = (props) => {
//   return <B {...props} />
// }
// // export const Router = ({ source, routes, useRoutes }) => {
// //   console.log(source, useRoutes);
// //   return <Provider store={store}>
// //     <AppRouter useRoutes={useRoutes} routes={routes} />
// //   </Provider>
// // }
// // export const ReduxProvider = ({ children }) => {
// //   console.log(source, useRoutes);
// //   return <Provider store={store}>
// //     {children}
// //   </Provider>
// // }
// export { ThemeColors, ThemeContext };


// export { useLayout, Application };
// export { getRoutes};