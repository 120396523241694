import { Button } from "reactstrap";
import { Trash2, Edit } from "react-feather";
import { UTC_TIMEZONE_LIST } from "../../utility/Utils";

const actionBodyTemplate = (onEdit, onDelete) => rowData => <div className="d-flex justify-content-center align-items-center">
    <Button onClick={() => onEdit(rowData)}
        color="flat-primary"
        className="btn-icon"
        size="sm" >
        <Edit id="edit" size={20} />
    </Button>
    {/* <Button onClick={() => onDelete(rowData)}
        color="flat-danger"
        className="btn-icon"
        size="sm" >
        <Trash2 id="delete" size={22} />
    </Button> */}
</div>

const localizedBodyTemplate = (propName, rowData) => {
    const value = rowData[propName];
    return `${value?.en}`;
};

const localizedBodyTemplateForCountry = (rowData) => {
    if (rowData?.countryName) {
        const parsedData = rowData?.countryName;
        if (parsedData?.en) {
            return `${parsedData?.en}`;
        } else {
            return "-";
        }
    } else {
        return "-";
    }
};

export const columns = (onEdit, onDelete) => [,
    { field: 'id', header: 'Code', filter: true, sortable: true },
    { field: 'name', header: 'Name', filter: true, sortable: true, body: (values) => localizedBodyTemplate("name", values) },
    { field: 'timeZoneVariation', header: 'Time Zone', filter: true, sortable: true,
        body: (values) => {
            return <span>{UTC_TIMEZONE_LIST.find(v=>v.value===values.timeZoneVariation)?.label||""}</span>
        }
     },
    { field: 'countryName', header: 'Country', filter: false, sortable: false, body: (values) => localizedBodyTemplateForCountry(values) },
    { field: 'actions', header: 'Actions', style: { width: '12rem' }, body: actionBodyTemplate(onEdit, onDelete) },

];
