import { Button } from "reactstrap";
import { Trash2, Edit } from "react-feather";

const actionBodyTemplate = (onEdit, onDelete) => rowData => <div className="d-flex justify-content-center align-items-center">
    <Button onClick={() => onEdit(rowData)}
        color="flat-primary"
        className="btn-icon"
        size="sm" >
        <Edit id="edit" size={20} />
    </Button>
    <Button onClick={() => onDelete(rowData)}
        color="flat-danger"
        className="btn-icon"
        size="sm" >
        <Trash2 id="delete" size={22} />
    </Button>
</div>

const localizedBodyTemplate = (propName, rowData) => {
    const value = rowData[propName];
    return `${value?.en}`;
};

export const columns = (onEdit, onDelete) => [
    { field: 'name', header: 'Name', body: (values) => localizedBodyTemplate("name", values), filter: true,sortable:true },
    { field: 'discreption', header: 'Discreption', body: (values) => localizedBodyTemplate("discreption", values) , filter: true,sortable:true},
    { field: 'mealId', header: 'Meal', filter: true,sortable:true},
    { field: 'market', header: 'Market', filter: true, sortable: true },
    { field: 'cancellationFee', header: 'Cancellation Fee', filter: true, sortable: true },
    { field: 'baggageAllowance', header: 'Baggage Allowance', body: (values) => localizedBodyTemplate("baggageAllowance", values), filter: true, sortable: true },
    { field: 'loungeAccess', header: 'Lounge Access', body: (values) => localizedBodyTemplate("loungeAccess", values), filter: true, sortable: true },
    { field: 'cancellation', header: 'Cancellation', body: (values) => localizedBodyTemplate("cancellation", values), filter: true, sortable: true },
    { field: 'changeFee', header: 'Market', filter: true, sortable: true },
    { field: 'change', header: 'Change', body: (values) => localizedBodyTemplate("change", values), filter: true, sortable: true },
    { field: 'actions', header: 'Actions', style: { width: '12rem' }, body: actionBodyTemplate(onEdit, onDelete) },
];
