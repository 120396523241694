import { Button } from "reactstrap";
import { Trash2, Edit } from "react-feather";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const actionBodyTemplate = (onEdit, onDelete) => rowData => <div className="d-flex justify-content-center align-items-center">
    <Button onClick={() => onEdit(rowData)}
        color="flat-primary"
        className="btn-icon"
        size="sm" >
        <Edit id="edit" size={20} />
    </Button>
    {/* <Button onClick={() => onDelete(rowData)}
        color="flat-danger"
        className="btn-icon"
        size="sm" >
        <Trash2 id="delete" size={22} />
    </Button> */}
</div>

export const getSeverity = (status) => {
    switch (status) {
        case 'Rejected':
            return 'danger';

        case 'Approved':
            return 'success';

        case 'Pending':
            return 'warning';

        default:
            return null;
    };
};

const getTypeSeverity = (type) => {
    switch (type) {
        case 'OneWayBooking':
            return 'info';
        default:
            return null;
    };
};

const idDetailBodyTemplate = (rowData) => {
    return <Link to={`/refund/details/${rowData.id}`} activeClassName="current">{rowData.pnr}</Link>;
};

const localizedBodyTemplate = (propName, rowData) => {
    const value = rowData[propName];
    return `${value?.en}`;
};

const localizedBodyTemplateForCity = (propName, rowData) => {
    if (rowData?.cityName) {
        const parsedData = JSON.parse(rowData?.cityName);
        if (parsedData?.en) {
            return `${parsedData?.en}`;
        } else {
            return "-";
        }
    } else {
        return "-";
    }
};

const getCityName = (values) => {
    const { allItems } = useSelector(state => state.city);
    if (allItems) {
        try {
            const parsedData = JSON.parse(allItems);
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    } else {
        console.warn("allItems is either undefined or an empty string");
    }
    let name = "";
    // allItems?.map((val) => {
    //     if (val?.id === values?.cityId) {
    //         name = val?.name?.en;
    //     }
    // });
    return name;
};

export const columns = (onEdit, onDelete) => [,
    { field: 'id', header: 'Code', filter: true, sortable: true },
    { field: 'name', header: 'Name', filter: true, sortable: true, body: (values) => localizedBodyTemplate("name", values) },
    { field: 'departureTerminal', header: 'Departure Terminal', sortable: true, filter: true, body: (values) => localizedBodyTemplate("departureTerminal", values) },
    { field: 'arrivalTerminal', header: 'Arrival Terminal', sortable: true, filter: true, body: (values) => localizedBodyTemplate("arrivalTerminal", values) },
    { field: 'intDepartureTerminal', header: 'Int. Departure Terminal', sortable: true, filter: true, body: (values) => localizedBodyTemplate("intDepartureTerminal", values) },
    { field: 'intArrivalTerminal', header: 'Int. Arrival Terminal', sortable: true, filter: true, body: (values) => localizedBodyTemplate("intArrivalTerminal", values) },
    { field: 'cityName', header: 'City', filter: true, sortable: true, body: (values) => localizedBodyTemplateForCity("cityName", values) },
    // { field: 'cityId', header: 'City', filter: true, sortable: true },
    { field: 'actions', header: 'Actions', style: { width: '12rem' }, body: actionBodyTemplate(onEdit, onDelete) },
    // { field: 'countryCode', header: 'Country Code', filter: true, sortable: true },
    // { field: 'countryName', header: 'Country', filter: true, sortable: true, body: (values) => localizedBodyTemplate("countryName", values) },
    // { field: 'latitude', header: 'Latitude', filter: true, sortable: true },
    // { field: 'latitude', header: 'Latitude', filter: true, sortable: true },
    // { field: 'longitude', header: 'Longitude', filter: true, sortable: true },
];
