import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'country/fetchAll',
    async (params) => {
        const response = await api.post("/countries/search", params);
        return response.data;
    }
);

export const getAllCountries = createAsyncThunk(
    'country/getAllCountries',
    async () => {
        try {
            const response = await api.get("/countries/getAll");
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error(error?.message);
        }
    }
);

export const fetchById = createAsyncThunk(
    'country/fetchById',
    async ({ id }) => {
        const response = await api.get(`/countries/${id}`)
        return response.data;
    }
);

export const create = createAsyncThunk(
    'country/create',
    async ({ item }) => {
        try {
            const response = await api.post(`/countries`, item);
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error(`Request failed with status code ${response.status}`);
            }
        } catch (err) {
            throw new Error(`${err?.message}`);
        };
    }
);

export const update = createAsyncThunk(
    'country/update',
    async ({ item }) => {
        try {
            const response = await api.put(`/countries`, item);
            return response.data;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
);

export const remove = createAsyncThunk(
    'country/remove',
    async ({ id }) => {
        try {
            await api.delete(`/countries/${id}`);
            return id;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
);

export const toggleModal = createAsyncThunk(
    'country/toggleModal',
    async (item) => {
        return item;
    }
);

const slice = createSlice({
    name: 'country',
    initialState: {
        items: [],
        allCountries: [],
        loading: false,
        error: null,
        errors: null,
        selectedItem: {},
        itemToDelete: null,
        deleteDialogOpened: false,
        openModal: false,
        Item: [],
        Mode: undefined,
    },
    reducers: {
        setItem: (state, { payload }) => {
            state.selectedItem = payload;
        },
        deleteItem: (state, { payload }) => {
            state.itemToDelete = payload;
            state.deleteDialogOpened = true;
        },
        closeDeleteDialog: (state) => {
            state.itemToDelete = undefined;
            state.deleteDialogOpened = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload);
            })
            .addCase(getAllCountries.fulfilled, (state, action) => {
                state.allCountries = action.payload;
            })
            .addCase(fetchById.fulfilled, (state, { payload }) => {
                state.selectedItem = payload
            })
            .addCase(create.fulfilled, (state, { payload, meta: { arg } }) => {
                // const newData = {
                //     ...payload,
                //     id: payload.code
                // }
                state.items.push(payload);
                state.openModal = false;
                state.Item = undefined;
                state.Mode = undefined;
                const { successCallBack } = arg;
                successCallBack && successCallBack();
            })
            .addCase(update.fulfilled, (state, { payload, meta: { arg } }) => {
                const index = state.items.findIndex((e) => e.id === payload.id);
                state.items[index] = payload;
                state.openModal = false;
                state.Item = undefined;
                state.Mode = undefined;
                const { successCallBack } = arg;
                successCallBack && successCallBack();
            })
            .addCase(remove.fulfilled, (state, { payload, meta: { arg } }) => {
                state.items = state.items.filter((e) => e.id !== payload);
                state.deleteDialogOpened = false;
                state.itemToDelete = undefined;
                const { successCallBack } = arg;
                successCallBack && successCallBack();
            })
            .addCase(toggleModal.fulfilled, (state, { payload, meta: { arg } }) => {
                state.openModal = payload.openModal;
                state.Item = payload.Item;
                state.Mode = payload.Mode;
                const { successCallBack } = arg;
                successCallBack && successCallBack();
            })
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.loading = true;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    state.loading = false;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                    state.errors = action.payload?.data?.errors;
                }
            );
    },
});

export const { setItem, deleteItem, closeDeleteDialog } = slice.actions;
export default slice.reducer;
