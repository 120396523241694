import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';

// import api from "axios";
export const fetchAll = createAsyncThunk(
    'bundle/fetchAll',
    async (params) => {
        const response = await api.post("/bundles/search", params );
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'bundle/fetchById',
    async ({ id }) => {
        const response = await api.get(`/bundles/${id}`)
        return response.data;
    }
);

export const create = createAsyncThunk(
    'bundle/create',
    async ({ newData }) => {
        try {
            const response = await api.post(`/bundles`, newData);
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error(`Request failed with status code ${response.status}`);
            };
        } catch (err) {
            throw new Error(`${err?.message}`);
        };
    }
);

export const update = createAsyncThunk(
    'bundle/update',
    async ({ newData }) => {
        try {
            const response = await api.put(`/bundles`, newData);
            return response.data;
        } catch (err) {
            throw new Error(`${err?.message}`);
        }
    }
);

export const remove = createAsyncThunk(
    'bundle/remove',
    async ({ id }) => {
        try {
            await api.delete(`/bundles/${id}`);
            return id;
        } catch (err) {
            throw new Error(`${err?.message}`);
        }
    }
);

const slice = createSlice({
    name: 'bundle',
    initialState: {
        items: [],
        loading: false,
        error: null,
        errors: null,
        selectedItem: {},
        itemToDelete: null,
        deleteDialogOpened: false,
        Item: {},
        Mode: undefined,
        currentPage: 1,
        totalPages: 1,
        totalCount: 10,
        pageSize: 10,
        hasPreviousPage: false,
        hasNextPage: false,
    },
    reducers: {
        setItem: (state, { payload }) => {
            state.selectedItem = payload;
        },
        deleteItem: (state, { payload }) => {
            state.itemToDelete = payload;
            state.deleteDialogOpened = true;
        },
        closeDeleteDialog: (state) => {
            state.itemToDelete = undefined;
            state.deleteDialogOpened = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, { payload }) => {
                handlePagination(state, payload);
                state.Item = {};
            })
            .addCase(create.fulfilled, (state, { payload, meta: { arg } }) => {
                state.items.push(payload);
                state.Item = {};
                const { successCallBack } = arg;
                successCallBack && successCallBack();
            })
            .addCase(update.fulfilled, (state, { payload, meta: { arg } }) => {
                const index = state.items.findIndex((e) => e.id === payload.id);
                state.items[index] = payload;
                state.Item = {};
                const { successCallBack } = arg;
                successCallBack && successCallBack();
            })
            .addCase(fetchById.fulfilled, (state, { payload }) => {
                // const selectedItem = state.items.find((item) => item.id == payload);
                state.Item = payload;
            })
            .addCase(remove.fulfilled, (state, { payload, meta: { arg } }) => {
                state.items = state.items.filter((e) => e.id !== payload);
                state.deleteDialogOpened = false;
                state.itemToDelete = undefined;
                const { successCallBack } = arg;
                successCallBack && successCallBack();
            })
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.loading = true;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => {
                    state.loading = false;
                    state.error = null;
                    state.errors = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                    state.errors = action.payload?.data?.errors;
                }
            );
    },
});

export const { setItem, deleteItem, closeDeleteDialog } = slice.actions;
export default slice.reducer;
