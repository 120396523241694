
// ** Third Party Components
import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocalizedInput, TextInput, SelectInput } from "@src/components/inputs";
import { useSelector, useDispatch } from "react-redux";
import { create, update, toggleModal } from "@src/redux/cities";
import { getAllCountries } from "@src/redux/countries";
import { Button, Form, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../@core/components/spinner/Loading-spinner";
import { UTC_TIMEZONE_LIST } from "../../utility/Utils";



const AddCities = () => {

    const dispatch = useDispatch();

    const { selectedItem, errors, error, openModal, Mode, Item, loading } = useSelector((state) => state.city);
    const { allCountries } = useSelector((state) => state.countries);

    useEffect(() => {
        dispatch(getAllCountries());
    }, []);

    useEffect(() => {
        reset(initialValues());
    }, [Mode]);

    const initialValues = () => {
        if (Mode !== "Edit") {
            return {
                name: { en: "", ar: "" },
                cityCode: "",
                countryId: "",
                timeZone: "",
            };
        } else {
            return {
                name: { en: Item?.name ? Item.name.en : "", ar: Item?.name ? Item.name.ar : "" },
                cityCode: Item?.id ? Item.id : "",
                id: Item.id ? Item.id : "",
                countryId: Item?.countryId ? Item?.countryId : "",
                timeZone: Item?.timeZoneVariation ? Item?.timeZoneVariation : "",
            };
        };
    };

    useEffect(() => reset(initialValues()), [selectedItem]);

    const localizedValidation = yup.object().shape({
        en: yup.string().required("English Name is Required"),
        ar: yup.string().required("Arabic Name is Required"),
    });

    const validationSchema = yup.object().shape({
        name: localizedValidation,
        cityCode: yup.string().required("City Code is Required"),
        countryId: yup.string().required("Country is Required"),
    });

    let onSubmit = (item) => {
        // console.log("Item", item);
        Item?.id
            ? dispatch(update({ item, successCallBack: () => { return; } }))
            : dispatch(create({ item, successCallBack: () => { return; } }));
    };

    // ** Hooks
    const methods = useForm({
        defaultValues: initialValues(),
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    const { handleSubmit, reset, getValues } = methods;

    const toggle = () => {
        dispatch(toggleModal({ openModal: false, Item: undefined, Mode: undefined }));
        reset(initialValues());
    };
    return (
        <>
            <Modal isOpen={openModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <h2>{Mode === "Edit" ? "Edit" : "Add"} City</h2>
                </ModalHeader>
                <ModalBody>
                    <FormProvider {...methods}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {loading &&
                                <div style={{ position: "absolute", marginLeft: "40%", marginTop: "30px", zIndex: "5" }}>
                                    <Loader />
                                </div>
                            }
                            <Row style={{ opacity: loading ? "0.5" : "1" }}>
                                {errors &&
                                    errors.map(err =>
                                        <Col className='mb-3' sm='12'>
                                            <div className="text-danger">{err.errorMessage}</div>
                                        </Col>
                                    )}
                            </Row>
                            <Row style={{ opacity: loading ? "0.5" : "1" }}>
                                <Col className="" sm="12">
                                    <LocalizedInput label="City" name="name" disabled={loading} />
                                </Col>
                                <Col className="mb-1" sm="12">
                                    <TextInput label="City Code" name="cityCode" disabled={loading || Mode == "Edit"} />
                                </Col>
                                <Col className="mb-1" sm="12">
                                    <SelectInput
                                        label="Country"
                                        name="countryId"
                                        isDisabled={loading}
                                        options={allCountries?.map(val => ({
                                            label: val?.name?.en,
                                            value: val?.id
                                        }))}
                                    />
                                </Col>
                                <Col className="mb-1" sm="12">
                                    <SelectInput
                                        label="Time Zone"
                                        name="timeZone"
                                        isDisabled={loading}
                                        v
                                        options={UTC_TIMEZONE_LIST||[]}
                                        // options={[]}
                                    />
                                </Col>
                            </Row>
                            {error &&
                                <div className="text-danger">{error}</div>
                            }
                        </Form>
                    </FormProvider >
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggle} disabled={loading}>
                        Cancle
                    </Button>{' '}
                    <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={loading}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AddCities;
