// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import airports from "./airports";
import terminals from "./terminals";
import city from "./cities";
import bundle from "./bundles";
import meal from "./meals";
import countries from "./countries";

const rootReducer = { navbar, layout, airports, terminals, city, bundle, meal, countries };

export default rootReducer;
