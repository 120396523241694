import { lazy } from 'react';

// const List = lazy(() => import('@src/views/airports/List'));
// const Form = lazy(() => import('@src/views/airports/Form'));
import List from "./List";

const PageLayoutRoutes = [
    {
        path: "/meals",
        index: true,
        element: <List />
    },
];

export default PageLayoutRoutes;
