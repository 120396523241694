import { lazy } from 'react';

// const List = lazy(() => import('@src/views/cities/List'));
import List from "./List";

const PageLayoutRoutes = [
    {
        path: "/cities",
        index: true,
        element: <List />
    },
];

export default PageLayoutRoutes;
