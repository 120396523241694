import { Button } from "reactstrap";
import { Trash2, Edit } from "react-feather";

const actionBodyTemplate = (onEdit, onDelete) => rowData => <div className="d-flex justify-content-center align-items-center">
    <Button onClick={() => onEdit(rowData)}
        color="flat-primary"
        className="btn-icon"
        size="sm" >
        <Edit id="edit" size={20} />
    </Button>
    {/* <Button onClick={() => onDelete(rowData)}
        color="flat-danger"
        className="btn-icon"
        size="sm" >
        <Trash2 id="delete" size={22} />
    </Button> */}
</div>

const localizedBodyTemplate = (propName, rowData) => {
    const value = rowData[propName];
    return `${value?.en}`;
};

const localizedBodyTemplateTerminal = (propName, rowData) => {
    if (rowData[propName]) {
        const value = JSON.parse(rowData[propName]);
        return `${value?.en}`;
    } else {
        return "";
    }
};

export const columns = (onEdit, onDelete) => [
    { field: 'name', header: 'Terminal Name', sortable: true, body: (values) => localizedBodyTemplate("name", values) },
    { field: 'airportName', header: 'Airport Name', sortable: true, body: (values) => localizedBodyTemplateTerminal("airportName", values) },
    { field: 'actions', header: 'Actions', style: { width: '12rem' }, body: actionBodyTemplate(onEdit, onDelete) },
];
