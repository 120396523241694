
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.min.css';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState, useRef } from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

function Table({ columns = [], values, metaData,
    tableState, setTableState, totalRecords, rowExpansionTemplate, advancedFilterLogic = "or", emptyMessage = "No data found.", ...props }) {
    const [visibleColumns, setVisibleColumns] = useState(columns);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const {
        currentPage,
        pageSize,
        totalCount,
        totalPages,
        loading
    } = metaData;
    const [filter, setFilter] = useState({
        pageNumber: 1,
        pageSize: 10,
        orderBy: [],
        advancedFilter: {},
        keyword: ""
    });
    const { globalFilterFields } = props;
    const onPage = (e) => {
        setTableState({
            ...tableState,
            pageNumber: e.page + 1,
            pageSize: e.rows,
        });
    };

    // const onSort = (event) => {
    //     debugger;
    //     setTableState(event);
    // };

    const onSort = (event) => {
        let order = [`${event.sortField}`];
 
        // if(order=='name'){
        //     order="json_value(Name,'$.ar')";
        // }
        // if(order == 'providerName'){
        //     order = 'Provider.Name';
        // }
 
        if (tableState.orderBy && tableState.orderBy[0] === order +' '+ 'Asc') {
            order += ' Desc';
        } else {
            order += ' Asc';
        }
        order = [order];
 
        setTableState({
            ...tableState,
            orderBy: order
        });
    };

    const getFilterOperator = (operator) => {
        switch (operator) {
            case 'equals' || 'dateIs':
                return 'eq';
            case 'dateBefore':
                return 'lt';
            case 'dateAfter':
                return 'gt';
            case 'dateIsNot':
                return 'neq';
            case 'notEquals':
                return 'neq';
            default:
                return operator
        }
    }

    const onFilter = (e) => {
        const filters = Object.entries(e.filters).map(([key, value]) => {
            if (value.constraints && value.constraints.length > 1) {
                return {
                    logic: value.operator,
                    filters: value.constraints.map((constraint) => {
                        if (constraint.value !== null && constraint.value !== "")
                            return {
                                field: key,
                                operator: getFilterOperator(constraint.matchMode),
                                value: constraint.value,
                            }
                    }),
                };
            } else {
                if (value.constraints[0].value === null || value.constraints[0].value === "")
                    return null;
                return {
                    field: key,
                    operator: getFilterOperator(value.constraints[0].matchMode),
                    value: value.constraints[0].value,
                };
            }
        });
        setTableState({
            ...tableState,
            advancedFilter: {
                logic: advancedFilterLogic,
                filters: filters.filter((f) => f !== null),
            },
            pageNumber: e.first + 1,
            pageSize: e.rows,
        });
    };

    const onColumnToggle = (event) => {
        debugger;
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        setVisibleColumns(orderedSelectedColumns);
    };

    const onRowExpand = (event) => {
        toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    };

    const renderHeader = () => {
        return <MultiSelect value={visibleColumns}
            options={columns}
            optionLabel="header"
            onChange={onColumnToggle} className="w-full sm:w-20rem" display="chip" />
    };

    const header = renderHeader();




    return (
        <>
            <Toast ref={toast} />
            {/* <DataTable
        // selectionMode="single"
        globalFilterFields={globalFilterFields}
        lazy
        first={tableState.first}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={tableState.sortField}
        sortOrder={tableState.sortOrder}
        onFilter={onFilter}
        filters={tableState.filters}
        loading={loading}
        tableStyle={{ minWidth: '75rem' }}
        scrollable
        // scrollHeight="70vh"
        stripedRows
        rowsPerPageOptions={[5, 10, 25, 50]}
        resizableColumns
        showGridlines
        // filterDisplay="row"
        header={header}
        emptyMessage="No data found."
        // paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        // onRowExpand={onRowExpand}
        // onRowCollapse={onRowCollapse}
        paginator
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rows={10}
        rowExpansionTemplate={rowExpansionTemplate}
        value={values}
      >
        {props.children}
        <Column expander={allowExpansion} style={{ width: '3rem' }} />
        {visibleColumns.map((col, i) => (
          <Column key={col.field}
            className="font-bold"
            filter={col.filter}
            sortable={col.sortable}
            filterPlaceholder="Search..."
            field={col.field}
            header={col.header}
            body={col.body}
          />
        ))} 
      </DataTable> */}
            <DataTable
                lazy
                value={values}
                paginator
                showGridlines
                rows={pageSize}
                owsPerPageOptions={[5, 10, 25, 50]}
                loading={loading}
                onFilter={onFilter}
                onSort={onSort}
                // filters={tableState.filters}
                // // header={header}
                onPage={onPage}
                paginatorPosition='both'
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                first={(currentPage - 1) * pageSize}
                totalRecords={totalCount}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                {...props}

                // totalRecords={tableState.totalRecords}
                emptyMessage={emptyMessage}>
                {/* {props.children} */}
                {expandedRows &&
                    <Column expander={true} style={{ width: '3rem' }} />
                }
                {visibleColumns.map((col, i) => (
                    <Column key={col.field}
                        className="font-bold"
                        filter={col.filter}
                        sortable={col.sortable}
                        filterPlaceholder="Search..."
                        field={col.field}
                        dataType={col.dataType}
                        header={col.header}
                        body={col.body}
                        filterElement={col.filterElement}
                    />
                ))}
            </DataTable>

        </>
    )
}

export default Table   