
// ** Third Party Components
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput, LocalizedInput, SelectInput, AutoCompleteInput } from "@src/components/inputs";
import { getCityByName } from "@src/redux/cities";
import { useSelector, useDispatch } from "react-redux";
import { create, update, fetchById } from "@src/redux/airports";
import Breadcrumbs from '@components/breadcrumbs';
import { Button, Form, Row, Col, Card, CardBody, Container } from "reactstrap";
import config from "@src/configs/themeConfig";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../@core/components/spinner/Loading-spinner";

const AddAirports = () => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const baseRoute = config.app.baseRoute;

    const navigate = useNavigate();

    const { selectedItem, errors, error, loading } = useSelector((state) => state.airports);
    const { allItems } = useSelector(state => state.city);

    useEffect(() => {
        id != "new" && dispatch(fetchById({ id }));
    }, []);

    const initialValues = (item) => {
        if (item && id != "new") {
            const newItem = {
                ...item,
                cityCode: item?.cityId,
                Code: item?.id,
            };
            return newItem;
        };
        return {
            name: { en: "", ar: "" },
            departureTerminal: { en: "", ar: "" },
            arrivalTerminal: { en: "", ar: "" },
            intArrivalTerminal: { en: "", ar: "" },
            intDepartureTerminal: { en: "", ar: "" },
            cityCode: "",
            Code: "",
        };
    };

    useEffect(() => reset(initialValues(selectedItem)), [selectedItem]);

    const localizedValidation = yup.object().shape({
        en: yup.string().required("English Name is Required"),
        ar: yup.string().required("Arabic Name is Required")
    });

    const validationSchema = yup.object().shape({
        name: localizedValidation,
        departureTerminal: localizedValidation,
        arrivalTerminal: localizedValidation,
        intArrivalTerminal: localizedValidation,
        intDepartureTerminal: localizedValidation,
        cityCode: yup.string().required("Select Valid City"),
        Code: yup.string().required("Code is Required"),
    });

    let onSubmit = (item) => {
        id != "new"
            ? dispatch(update({ item, successCallBack: () => navigate(-1) }))
            : dispatch(create({ item, successCallBack: () => navigate(-1) }));
    };

    // ** Hooks
    const methods = useForm({
        defaultValues: initialValues(selectedItem),
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    const { handleSubmit, reset, getValues } = methods;

    return (
        <>
            <Breadcrumbs title='Airports'
                data={[{
                    title: 'Airports',
                    link: `${baseRoute}/airports`,
                }, {
                    title: 'Form',
                }]}
            />
            <Card>
                <CardBody >
                    <FormProvider {...methods}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg="8" md="12" style={{ opacity: loading ? "0.5" : "1" }}>
                                    <Row>
                                        {errors &&
                                            errors.map((err) => (
                                                <Col className="mb-3" sm="12">
                                                    <div className="text-danger">{err.errorMessage}</div>
                                                </Col>
                                            ))}
                                    </Row>
                                    <Row>
                                        <Col className="mt-0" sm="6">
                                            <LocalizedInput label="Airport Name" name="name" disabled={loading} />
                                        </Col>
                                        <Col className="mt-0" sm="6">
                                            <LocalizedInput label="Departure Terminal" name="departureTerminal" disabled={loading} />
                                        </Col>
                                        <Col className="mt-0" sm="6">
                                            <LocalizedInput label="Arrival Terminal" name="arrivalTerminal" disabled={loading} />
                                        </Col>
                                        <Col className="mt-0" sm="6">
                                            <LocalizedInput label="Int. Departure Terminal" name="intDepartureTerminal" disabled={loading} />
                                        </Col>
                                        <Col className="mt-0" sm="6">
                                            <LocalizedInput label="Int. Arrival Terminal" name="intArrivalTerminal" disabled={loading} />
                                        </Col>
                                        <Col className="mt-1" sm="6">
                                            <TextInput label="Code" name="Code" disabled={loading || id != "new"} />
                                            {/* <SelectInput
                                                label="City"
                                                name="cityCode"
                                                defaultValue=""
                                                isDisabled={loading}
                                                options={allItems?.map(val => ({
                                                    label: `${val.name?.en}`,
                                                    value: val.id
                                                }))}
                                            /> */}
                                            <AutoCompleteInput
                                                label="City"
                                                name="cityCode"
                                                placeholder="City"
                                                dispatchAction={getCityByName}
                                                listing={allItems}
                                                defaultValues={(selectedItem?.cityName && id != "new") ? JSON.parse(selectedItem?.cityName)?.en : ""}
                                                mode={id}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        {error &&
                                            <div className="text-danger">{error}</div>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-end align-items-end">
                                        {/* <Button color="danger" onClick={() => navigate(-1)} >
                                            Cancle
                                        </Button>&nbsp; */}
                                        <Button type="submit" disabled={loading}>
                                            Submit
                                        </Button>
                                    </div>
                                </Col>
                                {loading &&
                                    <div style={{ position: "absolute", marginTop: "10px" }}>
                                        <Loader />
                                    </div>
                                }
                            </Row>
                        </Form>
                    </FormProvider >
                </CardBody>
            </Card>
        </>
    );
};

export default AddAirports;
