import { useFormContext, Controller } from 'react-hook-form'

import { Label, Input, FormFeedback, Row, Col, InputGroup, InputGroupText } from 'reactstrap'

import { Slack, User, Settings, Database, Edit2, Eye } from 'react-feather'


function TextInput({ label, icontext, icon, name, ...props }) {

    const { control, formState: { errors } } = useFormContext();

    return (
        <Row>
            <Col md='4' sm='12'>
                <Label className='form-label' for={name}>
                    <h6>{label}</h6>
                </Label>
            </Col>
            <Col md='8' sm='12'>
                <Controller
                    id={name}
                    name={name}
                    control={control}

                    render={({ field, fieldState: { error } }) => {
                        return (
                            icontext || icon ?
                                <InputGroup>
                                    <InputGroupText>
                                        {icontext ? icontext : <User />}
                                    </InputGroupText>
                                    <Input placeholder={label} invalid={error && true} {...field} prefix='XY' {...props} />
                                    {error && <FormFeedback>{error?.message}</FormFeedback>}
                                </InputGroup> :
                                <>
                                    <Input placeholder={label} invalid={error && true} {...field} prefix='XY' {...props} />
                                    {error && <FormFeedback>{error?.message}</FormFeedback>}
                                </>
                        )
                    }
                    }
                />
            </Col>

        </Row>
    )
}

export default TextInput


// function TextInput({ label, name }) {
//     const { control, getFieldState } = useFormContext();
//     const { error, isTouched } = getFieldState(name)

//     console.log('isTouched', isTouched);
//     console.log('error', error);
//     return (
//         <>
//             <Label className='form-label' for={name}>
//                 {label}
//             </Label>
//             <Controller
//                 id={name}
//                 name={name}
//                 control={control}
//                 render={({ field }) => (
//                     <Input placeholder={label} invalid={error} {...field} />
//                 )}
//             />
//             {error && <FormFeedback>{error}</FormFeedback>}
//         </>
//     )
// }

// export default TextInput